<template>
  <div class="info-box">
      <div class="info-content">
          <div class="info-tabs-content" :style="widthStyle">
              <div class="tabs-box" v-if="tabs && tabs.length">
                  <div class="tab-item-group">
                      <div class="tab-item" :class="index === key ? 'active': ''" v-for="(item, key) in tabs" :key="key" @click="activeIndex(key)">
                          <span>{{item}}</span>
                      </div>
                  </div>
                  <div class="tab-item-btn" v-if="!status">
                      <el-button type="primary" @click="goBack">返回</el-button>
                  </div>
              </div>
              <div class="project-info-body" v-if="index === 0">
                  <div class="project-header-main">
                      <div class="project-header-bg"></div>
                      <div class="project-header-content">
                          <div class="header-title">
                              <h1>{{project.project_title}}</h1>
                          </div>
                          <div class="header-duration-box">
                              <div class="bg-ellipse-box">
                                  <div class="bg-ellipse-dot dot-left"></div>
                                  <div class="bg-ellipse-dot dot-right"></div>
                                  <div class="ellipse-text">
                                      <span>建议时长：{{duration}}min</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="info-sub-item">
                      <div class="sub-item-box" v-for="(item, index) in project.project_attrs" :key="index" v-if="item.value">
                          <div class="sub-item-title-box">
                              <div class="s-item-title-center">
                                  <div class="header-duration-box medium-size">
                                      <div class="bg-ellipse-box blue-b">
                                          <div class="bg-ellipse-dot dot-left"></div>
                                          <div class="bg-ellipse-dot dot-right"></div>
                                          <div class="ellipse-text auto-width">
                                              <span>{{item.name}}</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="sub-item-content">
                              <div class="text-content">
                                  <p>{{item.value}}</p>
                              </div>
                          </div>
                      </div>
                      <div class="sub-item-box" v-if="project.project_sucai && project.project_sucai.length">
                          <div class="sub-item-title-box">
                              <div class="s-item-title-center">
                                  <div class="header-duration-box medium-size">
                                      <div class="bg-ellipse-box blue-b">
                                          <div class="bg-ellipse-dot dot-left"></div>
                                          <div class="bg-ellipse-dot dot-right"></div>
                                          <div class="ellipse-text auto-width">
                                              <span>素材下载</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="sub-item-content">
                              <div class="sucai-links-content">
                                  <div class="sucai-link-item" v-for="(item, index) in project.project_sucai" :key="index">
                                      <span class="sucai-icon"><i class="el-icon-document"></i></span>
                                      <span class="file-name">{{item.file_name}}</span>
                                      <a class="download-btn" :href="`${project.base_url}/${item.file_path}`" target="_blank"><i class="el-icon-download"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="sub-item-box"  v-if="app_lists && app_lists.length">
                          <div class="sub-item-title-box">
                              <div class="s-item-title-center">
                                  <div class="header-duration-box medium-size">
                                      <div class="bg-ellipse-box blue-b">
                                          <div class="bg-ellipse-dot dot-left"></div>
                                          <div class="bg-ellipse-dot dot-right"></div>
                                          <div class="ellipse-text auto-width">
                                              <span>应用中心</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="sub-item-content">
                              <div class="app-list-box">
                                  <div class="app-item" @click="showDialogApp(item)" v-for="(item, index) in app_lists" :key="index">
                                      <div class="app-cover">
                                          <img :src="item.logo" :alt="item.logo">
                                      </div>
                                      <div class="app-name">
                                          <span>{{item.app_name}}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="sub-item-box">
                          <div class="sub-item-title-box">
                              <div class="s-item-title-center">
                                  <div class="header-duration-box medium-size">
                                      <div class="bg-ellipse-box blue-b">
                                          <div class="bg-ellipse-dot dot-left"></div>
                                          <div class="bg-ellipse-dot dot-right"></div>
                                          <div class="ellipse-text auto-width">
                                              <span>{{project.project_task.name}}</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="sub-item-content">
                              <div class="text-content">
                                  <p v-html="project.project_task.value"></p>
                              </div>
                          </div>
                      </div>
                      <div class="sub-item-box">
                          <div class="sub-item-title-box">
                              <div class="s-item-title-center">
                                  <div class="header-duration-box medium-size">
                                      <div class="bg-ellipse-box blue-b">
                                          <div class="bg-ellipse-dot dot-left"></div>
                                          <div class="bg-ellipse-dot dot-right"></div>
                                          <div class="ellipse-text auto-width">
                                              <span>项目评价</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="sub-item-content" style="padding: 50px 0;">
                              <el-table :data="project.project_evaluate" style="width:100%;" header-row-class-name="eval-header" stripe>
                                  <el-table-column prop="index" label="序号" align="center" width="66px"></el-table-column>
                                  <el-table-column prop="content" label="内容" align="center" width="200px"></el-table-column>
                                  <el-table-column prop="evaluate" label="要求" align="center"></el-table-column>
                                  <el-table-column prop="score" class-name="last-th" label="分值" align="center" width="66px"></el-table-column>
                              </el-table>
                          </div>
                      </div>
                      <div class="sub-item-box" v-if="project.project_ability">
                          <div class="sub-item-title-box">
                              <div class="s-item-title-center">
                                  <div class="header-duration-box medium-size">
                                      <div class="bg-ellipse-box blue-b">
                                          <div class="bg-ellipse-dot dot-left"></div>
                                          <div class="bg-ellipse-dot dot-right"></div>
                                          <div class="ellipse-text auto-width">
                                              <span>能力拓展</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="sub-item-content">
                              <div class="text-content">
                                  <p>
                                      {{project.project_ability}}
                                  </p>
                              </div>
                          </div>
                      </div>
                      <slot name="extender"></slot>
                  </div>
              </div>
              <template v-if="index === 1">
                  <div class="project-extend">
                      <slot name="extend"></slot>
                  </div>
              </template>
          </div>
          <div class="info-fix-links" v-if="index === 0">
              <div class="fix-links-header">
                  <div class="links-head-txt">
                      <span class="bottom-link"></span>
                      <span>知识链接</span>
                  </div>
              </div>
              <div class="fix-links-lists">
                  <div class="link-item-box" v-if="project.project_knowledge_link.length == 0">
                      <div class="no-link-item-content">
                          <img src='@/assets/images/project/no_link.png' class="no-link"  alt="">
                          <span class="no-link-title">暂无知识链接</span>
                      </div>
                  </div>
                  <el-scrollbar style="height: 100%;" v-else>
                      <div class="link-item-box" v-for="(item, index) in project.project_knowledge_link" :key="index">
                          <div class="link-item-content" @click="viewDialog(item)">
                              <span class="link-icon" :class="typeClass(item.name, item.type)"></span>
                              <span class="link-title">{{item.name}}</span>
                          </div>
                      </div>
                  </el-scrollbar>
              </div>
          </div>
      </div>
      <div class="dialog-app">
          <el-dialog
              :title="`欢迎使用${app_info.app_name}`"
              :visible.sync="appDialogVisible"
              width="567px">
              <div class="app-dialog-content">
                  <el-scrollbar style="height: 100%">
                      <div class="dialog-app-name">
                          <span>欢迎使用{{app_info.app_name}}</span>
                      </div>
                      <div class="dialog-app-info">
                          <p v-html="app_info.intro">
                          </p>
                      </div>
                  </el-scrollbar>
              </div>
              <div slot="footer" class="dialog-app-footer">
                  <div class="dialog-app-batons">
                      <a :href="app_info.home_link" target="_blank"><el-button type="primary" size="small">首页登录</el-button></a>
                      <a :href="app_info.shop_link" target="_blank"><el-button size="small">商家登录</el-button></a>
                      <a :href="app_info.teacher_link" target="_blank"><el-button size="small">教师登录</el-button></a>
                  </div>
              </div>
          </el-dialog>
      </div>
      <div class="dialog-links">
          <el-dialog :visible.sync="linkDialogVisible" width="80%" top="5vh" @close="resetLink">
              <div class="link-dialog-content">
                  <iframe :src="linkUrl" frameborder="0" width="100%" height="600" v-if="linkType === 1"></iframe>
                  <video v-if="linkType === 2" controls>
                      <source :src="linkUrl">
                  </video>
              </div>
              <div slot="footer" class="dialog-footer">
                  <el-button @click="linkDialogVisible = false" size="small">关闭</el-button>
                  <el-button type="primary" @click="openView" size="small">新窗口打开</el-button>
              </div>
          </el-dialog>
      </div>
  </div>
</template>

<script>
export default {
    name: "ProjectInfo",
    props: {
        id: [String, Number],
        tabs: {
            type: Array,
        },
        project: {
            type: Object
        },
        app_lists: {
            type: Array,
        },
        status: [Number, String]
    },
    computed: {
        widthStyle() {
            if (this.index === 0) {
                return 'width: 890px';
            }
            return 'width: 100%';
        },
        duration() {
            return Number(this.project.project_duration) * 45;
        },
    },
    data() {
        return {
            // id: this.$route.query.id ? Number(this.$route.query.id) : null,
            /*project: {
                project_title: '',
                project_ability: [],
                project_attrs: [],
                project_evaluate: [],
                project_knowledge_link: [],
                project_sucai: [],
                project_task: [],
                project_duration: 0,
                project_difficulty: 0
            },
            app_lists: [],*/
            index: 0,
            app_info: {
                app_name: '',
                home_link: '',
                shop_link: '',
                teacher_link: '',
                intro: ''
            },
            appDialogVisible: false,
            linkDialogVisible: false,
            linkType: '',
            linkUrl: null
        }
    },
    created() {
    },
    methods: {
        showDialogApp(app_info) {
            this.app_info = {...app_info};
            this.appDialogVisible = true;
        },
        activeIndex(index) {
            this.index = Number(index);
        },
        goBack() {
            this.$router.go(-1);
        },
        viewDialog(item) {
            this.linkUrl = `${this.project.base_url}/pdf/${item.id}`;
            this.linkType = 1;
            if (item.url.indexOf('.mp4') !== -1 || item.url.indexOf('.avi') !== -1) {
                this.linkType = 2
                this.linkUrl = item.url;
            }
            if (item.type === 7) {
                this.linkType = 1;
                this.linkUrl = item.url;
            }
            this.linkDialogVisible = true;
        },
        openView() {
            window.open(this.linkUrl);
        },
        resetLink() {
            this.linkUrl = null;
            this.linkType = null;
        },
      /*project_info() {
          this.$http.axiosGetBy(this.$api.getProjectDetail, {id: this.id}, (res) => {
              if (res.code === 200)
              {
                  this.project = Object.assign({}, res.data);
                  this.app_lists = res.data.app_lists;
              }
          }, (err) => {
              this.$common.axiosErrorMsg(err);
          })
      },*/
      typeClass(name, type) {
          if (Number(type) === 7) {
              return 'animation-link';
          }
          let tmp_array = name.split(".");
          let ext = tmp_array[tmp_array.length - 1];
          let classname = '';
          switch (ext) {
              case 'docx':
                  classname = 'textbook';
                  break;
              case 'ppt':
                  classname = 'coureware';
                  break;
              case 'mp4':
                  classname = 'micro-lesson';
                  break;
              case 'pdf':
                  classname = 'person-resource';
                  break;
              default:
                  break;
          }
          return classname;
      },

  }
}
</script>

<style lang="scss" scoped>
  @import "./css/project_info";
</style>